<template>
  <Modal :visible="visible" id="modal-termos-advice" size="md" @hidden="$emit('hidden')">
    <section class="flex-col items-center gap-2 p-2">
      <span class="text text-lg font-bold">Atenção</span>
      <div class="flex-col items-center gap-1">
        <p class="text text-center">
          O aceite aos termos de uso são essenciais para garantir uma experiência segura e justa para todos os usuários. O acesso completo à plataforma só será concedido após o aceite dos termos.
        </p>
        <p class="text text-center">
          Se tiver algum ponto em discordância entre em contato com o produtor do curso. 
        </p>
        <p class="text text-center">
          Clique em "Aceitar" abaixo para continuar navegando. Obrigado!
        </p>
      </div>
      <ButtonPlay type="normal" @click="$emit('submit')">
        <b>Aceitar termos de uso</b>
      </ButtonPlay>
    </section>
  </Modal>
</template>

<script>

export default {
  props: ["visible"],
  computed: {
  },
  mounted() {
  }
};
</script>

<style lang="scss" scoped>

.text {
  color: var(--fontcolor);
}

.text-lg {
  font-size: 1.50rem;
}

.text-center {
  text-align: center;
}

.justify-between {
  justify-content: space-between;
}

</style>
